@use "../Css-functions/styles";

.bg-img {
  height: 350px;
  opacity: 0.2;
  filter: blur(3px);
  overflow: hidden;
}

.bg-img img {
  width: max-content;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation: scroll-left-right 50s linear infinite;
}
@keyframes scroll-left-right {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
  50% {
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
}

@media (prefers-reduced-motion: reduce) {
  .bg-img img {
    animation: none;
  }
}

.banner-section {
  position: relative;
}
.banner-content {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  color: styles.$primary-color;
}
.banner-content a {
  width: fit-content;
  font-size: 16px;
  color: styles.$dark-bg-color;
  padding: 12px 24px;
  border-radius: 1px;
  background: styles.$highlight-color;
  box-shadow: none;
}
.banner-tittle {
  font-size: 36px;
}
.banner-subtext {
  font-size: 14px;
  opacity: 0.7;
}
.projects-container {
  display: flex;
  flex-direction: column;
  gap: 80px;
  padding-top: 80px;
}
.projects-main {
  display: flex;
  align-items: center;
  gap: 80px;
  justify-content: center;
}
.projects-title {
  @include styles.tittle;
  text-transform: uppercase;
}
.projects-desc {
  color: styles.$text-accent;
  font-style: italic;
  width: 500px;
  line-height: 24px;
  font-size: 14px;
  letter-spacing: 0.02em;
}
.project-card {
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 20px;
  cursor: pointer;
  text-decoration: none;
}
.project-card:hover {
  .img-container img {
    transform: scale(1.14);
  }
}
.img-container {
  height: auto;
  width: 500px;
  background: #333;
  overflow: hidden;
}
.img-container img {
  transition: 0.3s ease-in-out;
}

.project-details {
  display: flex;
  color: styles.$primary-color;
  justify-content: space-between;
}
.project-card-title span {
  opacity: 0.5;
}
.projects-cards-main {
  display: grid;
  //grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-columns: auto auto;
  gap: 80px;
  justify-content: center;
}
.img-container img {
  width: inherit;
}
// Responsive Design Starts Here
@media screen and (min-width: 2560px) {
  .projects-cards-main {
    grid-template-columns: auto auto auto auto;
  }
}
@media only screen and (min-width: 1800px) and (max-width: 2559px) {
  .projects-cards-main {
    grid-template-columns: auto auto auto;
  }
}
@media only screen and (min-width: 990px) and (max-width: 1024px) {
  .projects-cards-main {
    grid-template-columns: auto auto;
  }
  .bg-img img {
    width: 100%;
    animation: none;
    object-fit: cover;
  }
}
@media screen and (max-width: 990px) {
  .projects-cards-main {
    grid-template-columns: auto;
    gap: 40px;
  }
}
@media screen and (max-width: 768px) {
  .projects-container {
    gap: 40px;
  }
  .projects-main {
    gap: 10px;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 40px;
  }
  .projects-title {
    font-size: 32px;
  }
  .projects-desc {
    width: -webkit-fill-available;
  }
 
  .img-container {
    height: 300px;
    width: 300px;
    background: #333;
  }
  .banner-tittle {
    font-size: 28px;
  }
  .banner-subtext {
    width: max-content;
  }
  .bg-img img {
    width: 100%;
    animation: none;
    object-fit: cover;
  }
}
