@use "../Css-functions/styles";
.consulatation-main {
  display: flex;
  justify-content: center;
  padding: 80px 0 ;
}
.consulatation {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px;
  width: 800px;
  background: rgba(188, 225, 62, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
}
.consulatation h2 {
  @include styles.tittle;
  font-size: 24px;
  text-shadow: none;
}
.consulatation p {
  font-family: styles.$font-futura-medium;
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: styles.$primary-color;
  opacity: 0.6;
}
.schedule-call {
  padding: 8px 16px;
  border: 0.5px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  font-size: 12px;
  width: fit-content;
  color: styles.$primary-color;
  cursor: pointer;
  transition: 0.2s ease-in;
}
.schedule-call:hover {
  background: styles.$primary-color;
  opacity: 0.5;
  color: styles.$dark-bg-color;
}
@media screen and (max-width: 1024px) {
  .consulatation {
    width: -webkit-fill-available;
  }
  .consulatation-main{
    padding: 40px;
  }
}