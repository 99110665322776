@use "../Css-functions/styles";

.error-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 30px;
}
.error-main img {
  height: 80%;
  width: 80%;
}
.error-main button {
  @include styles.ctaButton;
  width: max-content;
}
