@use "../Css-functions/styles";

.contact-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 80px;
}

.contact-link p {
  @include styles.tittle;
  text-shadow: none;
  font-size: 36px;
}
.contact-link button {
  @include styles.ctaButton;
  width: max-content;
}

.contact-link button:hover {
    box-shadow: none;
    position: relative;
    top: 6px;
    left: 6px;
  }
  @media screen and (max-width: 480px) {
    .contact-link p {
      text-align: center;
      font-size: 24px;
    }
    .contact-link button {
      font-size: 14px;
    }
  }