html {
    scroll-behavior: smooth;
    cursor: url('./assets/brush.svg'), auto;	
  }  
body{
    margin: 0;
    font-family: "Futura";
    color: #acacac;
    background: #090909;
}
.main-container::-webkit-scrollbar {
  display: none;
}
.App{
  overflow-x: hidden;
}
p,
ul,
h1,
h3,
h4,
h5,
h6,
h2,
figure {
  margin: 0;
  padding: 0;
}

.visuallyhidden {
  display: none;
}

a {
  text-decoration: none;
}

html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}
.g-bg {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -9999;
}
.g-bg div {
  width: 500px;
  height: 500px;
  border-radius: 100%;
  background: linear-gradient(105deg, rgba(240, 84, 84, 0.1) 15.7%, rgba(245, 145, 21, 0.1) 156.19%);
  filter: blur(1000px);
}
