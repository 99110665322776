 @use "../Css-functions/styles";

.header-container {
  height: 80px;
  box-shadow: 0px 4px 30px rgb(0 0 0 / 8%);
  display: flex;
  gap: 80px;
  align-items: center;
  justify-content: flex-start;
  padding: 0 80px;
  position: fixed;
  width: -webkit-fill-available;
  z-index: 1;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.nav-links {
  margin: 0;
  padding: 0;
  display: flex;
  gap: 40px;
}
.active {
  color: styles.$highlight-color !important;
}
.menu-links {
  color: styles.$primary-color;
  text-decoration: none;
  font-size: 18px;
}
.nav-links li {
  list-style: none;
  color: styles.$primary-color;
  font-family: styles.$font-futura-condensed;
  cursor: pointer;
  transition: 0.2s ease-in;
}
.nav-links li:hover {
  transform: scale(1.3);
}
.logo{
  display: flex;
}
.logo img {
  height: 24px;
}

@media screen and (max-width: 480px) {
  .header-container {
    height: 60px;
    padding: 0 30px;
    justify-content: flex-start;
    gap: 50px;
  }
  .menu-links {
    font-size: 16px;
  }
  .nav-links {
    gap: 20px;
  }
  .nav-links li {
    font-size: 14px;
  }
}
