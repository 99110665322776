$font-heading: "Noteworthy";
$font-futura-medium: "Futura-Medium";
$font-futura-condensed: "Futura-Medium-Condensed";
$font-futura-bold: "Futura-Bold";
$font-bonheur-royale: "Bonheur-Royale";
$primary-color: #fff;
$text-accent: #A1A1A1;
$secondary-color: #EC5800;
$highlight-color: #FFA000;
$dark-bg-color: #090909;
$light-bg-color: #fff;
$text-shadow: 2px 2px 2px #f59115;
$btn-shadow: 6px 6px 0px #f59115;
$footer-bg: #191a19;

@mixin tittle {
  font-family: $font-heading;
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  color: $primary-color;
  text-shadow: $text-shadow;
  letter-spacing: 1px;
}

@mixin ctaButton {
  background: $highlight-color;
  box-shadow: 6px 6px 0px $secondary-color;
  border-radius: 1px;
  padding: 18px 48px;
  text-decoration: none;
  color: $dark-bg-color;
  cursor: pointer;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  font-family: $font-futura-medium;
}

body {
  background: $dark-bg-color;
  font-family: $font-futura-medium;
  color: #acacac;
}

body.light {
  background: $light-bg-color;
  font-family: $font-futura-medium;
}

@font-face {
  font-family: "Noteworthy";
  src: url("../assets/fonts/Noteworthy-Bold.ttf");
}

@font-face {
  font-family: "Futura-Medium";
  src: url("../assets/fonts/Futura-Medium.ttf");
}
@font-face {
  font-family: "Bonheur-Royale";
  src: url("../assets/fonts/BonheurRoyale-Regular.ttf");
}
@font-face {
  font-family: "Futura-Medium-Condensed";
  src: url("../assets/fonts/Futura-Medium-Condensed.ttf");
}
@font-face {
  font-family: "Futura-Bold";
  src: url("../assets/fonts/Futura-Bold.ttf");
}

