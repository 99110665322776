@use "../Css-functions/styles";
.footer-container {
  background: styles.$footer-bg;
  display: flex;
  flex-direction: column;
  
}
.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 50px 80px;
}
.quote-icon {
  opacity: 0.08;
  position: absolute;
  top: -40px;
  left: -30px;
  color: styles.$primary-color;
  font-size: 72px;
}
.quote-section {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 20px;
  color: styles.$primary-color;
  font-style: italic;
  font-weight: 400;
}
.quote-section p {
  font-size: 18px;
  width: 450px;
  line-height: 32px;
  font-family: styles.$font-futura-medium;
  font-style: italic;
}
.quote-section span {
  font-size: 14px;
  opacity: 0.3;
  font-family: styles.$font-futura-medium;
}
.footer-links-header {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.footer-links-header p {
  font-size: 24px;
  font-family: styles.$font-heading;
  font-weight: 700;
  color: styles.$primary-color;
}
.divider {
  height: 1px;
  background: styles.$primary-color;
  opacity: 0.2;
}
.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: -webkit-fill-available;
  bottom: 0;
  color: #fff;
  font-size: 14px;
  margin: 30px 80px;
}
.footer-links {
  margin: 0;
  padding: 0;
  display: flex;
  gap: 30px;
}
.footer-social-icons {
  font-size: 26px;
  transition: 0.3s ease-in-out;
}
.footer-links li {
  list-style: none;
}
.footer-links li a {
  color: styles.$primary-color;
  text-decoration: none;
  font-weight: 500;
}
.footer-links li a:hover {
  color: styles.$highlight-color;
  
  .footer-social-icons{
    transform: scale(1.3);
    transition: 0.3s ease-in-out;
  }
}
.cprt-text {
  font-size: 12px;
  opacity: 0.5;
}

@media screen and (max-width: 480px) {
  .footer-top{
    margin: 40px 20px;
    flex-direction: column;
    gap: 30px;
    align-items: flex-start;
  }
  .quote-section p{
    width: -webkit-fill-available;
    font-size: 14px;
    margin: 0 20px;
  }
  .quote-icon {
    top: -25px;
    left: 0px;
    font-size: 56px;
}
.footer-links-header {
  gap: 20px;
}
  .footer-social-icons {
    font-size: 18px;
}
  .footer-bottom {
    margin: 20px;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
  .footer-bottom p {
    line-height: normal;
    opacity: 0.5;
    font-size: 12px;
  }
  .footer-links {
    gap: 20px;
  }
  .footer-container {
    font-size: 12px;
  }
  .footer-links li a {
    font-size: 16px;
  }
}
