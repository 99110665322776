@use "../Css-functions/styles";

.contact-container {
  display: flex;
  flex-direction: column;
  padding: 80px 0;
  justify-content: center;
  align-items: center;
  position: relative;
}
.contact-main{
  display: flex;
  flex-direction: column;
 align-items: flex-start;
 gap: 60px;
}
.contact-main p{
  @include styles.tittle;
  font-size: 48px;
}
.contact-main-content {
  display: flex;
  gap: 80px;
  align-items: flex-start;
}
.contact-left-section {
  display: flex;
  gap: 80px;
  align-items: flex-start;
}

.contact-right-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 50px;
}
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.contact-form div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-form span {
  background: styles.$primary-color;
  height: 1px;
  width: 400px;
  opacity: 0.2;
}
.contact-form div label {
  font-size: 16px;
  color: styles.$primary-color;
  font-family: styles.$font-futura-medium;
}
.contact-form div .inp-field {
  border: none;
  outline: none;
  font-size: 18px;
  font-family: styles.$font-futura-medium;
  height: 30px;
  width: 400px;
  color: styles.$highlight-color;
  background: transparent;
}
.contact-form div input::placeholder {
  font-size: 18px;
  font-family: styles.$font-futura-medium;
  color: styles.$primary-color;
  opacity: 0.2;
}
.contact-profile {
  height: 100px;
  width: 100px;
  border-radius: 100%;
  background: rebeccapurple;
}
.contact-info {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.contact-details,
.social-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.contact-details a {
  display: flex;
  align-items: center;
  gap: 8px;
  color: styles.$primary-color;
  transition: 0.2s ease-in-out;
}
.contact-details a:hover {
  color: styles.$highlight-color;
  // transform: scale(1.1);
}
.contact-details h6,
.social-details h6 {
  margin: 0;
  font-family: styles.$font-futura-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: styles.$primary-color;
  opacity: 0.5;
}
.contact-details p,
.social-details p {
  margin: 0;
  font-family: styles.$font-futura-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: styles.$primary-color;
}
.btn-submit {
  @include styles.ctaButton;
}
.btn-submit:hover {
  box-shadow: none;
  position: relative;
  top: 6px;
  left: 6px;
}
.btn-submit:active {
  transform: scale(0.9);
}
.social-icons {
  display: flex;
  gap: 20px;
  font-size: 20px;
}
.social-icons a {
  color: styles.$primary-color;
  transition: 0.3s ease-in-out;
}
.social-icons a:hover {
  transform: scale(1.5);
  transition: 0.3s ease-in-out;
  color: styles.$highlight-color;
}

//Responsive Design Starts from here

@media screen and (max-width: 1024px) {
  .contact-left-section {
    flex-direction: column;
  }
  .contact-container {
    height: fit-content;
  }
}

@media screen and (max-width: 480px) {
  .contact-container {
    padding: 40px 0;
    flex-direction: column;
    align-items: flex-start;
    height: fit-content;
    gap: 30px;
  }
  .contact-main-content {
    flex-direction: column;
  }
  .contact-main p{
    font-size: 32px;
  }
  .contact-right-section {
    flex-direction: column;
    gap: 40px;
  }
  .contact-form div .inp-field {
    width: 300px;
    font-size: 16px;
  }
  .contact-form div input::placeholder {
    font-size: 16px;
  }
  .contact-form span {
    width: -webkit-fill-available;
  }
  .contact-form {
    gap: 20px;
  }
  .btn-submit {
    padding: 14px 36px;
    font-size: 16px;
  }
  .contact-form div label {
    font-size: 14px;
  }
  .contact-details h6,
  .social-details h6 {
    font-size: 14px;
  }
  .contact-details p,
  .social-details p {
    font-size: 16px;
  }
  .contact-info {
    gap: 20px;
  }
  .contact-main{
    padding: 0 40px;
    gap: 40px;
  }
}
