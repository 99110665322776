@use "../Css-functions/styles";

.about-main-container {
  display: flex;
  flex-direction: column;
  gap: 80px;
}
.about-section-one {
  display: flex;
  padding: 0 80px;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  background-image: url("../assets/abt-bg.svg");
}
.about-hero {
  color: styles.$primary-color;
  font-size: 48px;
  line-height: 100px;
  width: 80%;
}
.about-section-two {
  display: flex;
  gap: 80px;
  padding: 80px;
  justify-content: center;
  align-items: flex-start;
  background: rgba(255, 255, 255, 0.05);
}
.section-two-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.profile-img {
  background: rgb(225, 225, 225, 0.1);
  width: 350px;
  height: 500px;
  border-radius: 5px;
  overflow: hidden;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.profile-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.greeting {
  letter-spacing: 2px;
  font-size: 2rem;
  color: styles.$highlight-color;
  font-family: styles.$font-bonheur-royale;

}
.bio {
  font-size: 16px;
  color: styles.$text-accent;
  line-height: 28px;
  letter-spacing: 0.02em;
  width: 700px;
}
.bio-link {
  font-size: 18px;
  color: styles.$primary-color;
  cursor: pointer;
  width: fit-content;
  display: flex;
  gap: 5px;
  align-items: flex-end;
  transition: 0.2s ease-in;
}
.bio-link:hover {
  color: styles.$highlight-color;
}
.about-section-three {
  padding: 80px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: rgba(255, 255, 255, 0.05);
}
.section-three-main {
  display: flex;
  justify-content: space-around;
  flex-grow: 1;
  flex-shrink: 1;
}
.col-one,
.col-two,
.col-three {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.about-section-three div h2 {
  @include styles.tittle;
  font-size: 32px;
  text-shadow: none;
  letter-spacing: 0.02em;
}
.about-section-three div ul {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.about-section-three div ul li {
  font-size: 16px;
  color: styles.$text-accent;
  letter-spacing: 0.02em;
  list-style: none;
}
.profile-stats-main {
  display: flex;
}
.profile-stats {
  border: 1px solid transparent; /* set the border width and make it transparent */
  border-image: linear-gradient(
      to bottom right,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.05)
    )
    1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.stats-num {
  font-size: 48px;
  font-family: styles.$font-futura-bold;
  color: styles.$primary-color;

}
.stats-info {
  font-size: 16px;
  color: styles.$text-accent;

}

@media screen and (max-width: 768px) {
  .about-section-one {
    padding: 0 40px;
  }
  .about-section-two {
    flex-direction: column;
    padding: 40px;
  }
  .profile-img {
    width: -webkit-fill-available;
    height: 400px;
  }
  .bio {
    font-size: 14px;
    width: -webkit-fill-available;
  }
  .bio-link {
    font-size: 16px;
  }
  .profile-stats {
    padding: 20px;
  }
  .stats-num {
    font-size: 36px;
  }
  .stats-info {
    font-size: 10px;
  }
  .about-section-three {
    padding: 40px;
  }
  .section-three-main {
    flex-direction: column;
    gap: 40px;
  }
  .about-section-three div ul li {
    font-size: 14px;
  }
  .about-section-three div title {
    font-size: 24px;
  }
  .about-hero {
    font-size: 24px;
    line-height: 48px;
    width: auto;
  }
}




//Work section starts here


.section-one {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.main-hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 100px;
}
.greet {
  display: flex;
  flex-direction: column;
  gap: 80px;
}
.greet span {
  font-size: 2.5rem;
  letter-spacing: 2px;
  font-family: styles.$font-bonheur-royale;
}
.job-title {
  display: flex;
  align-items: flex-start;
  gap: 50px;
}
.job-title span {
  font-family: styles.$font-bonheur-royale;
  font-size: 12rem;
  letter-spacing: 2px;
  line-height: 100px;
  color: styles.$highlight-color;
}
.main-title {
  font-size: 10rem;
  font-family: styles.$font-futura-bold;
  color: styles.$primary-color;
  line-height: 100px;
}
.align-links {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.name-seperator {
  margin-top: 30px;
  height: 4px;
  width: 300px;
  background: styles.$highlight-color;
}
.social-links {
  margin: 0;
  padding: 0;
  display: flex;
  gap: 30px;
}
.social-links li {
  list-style: none;
}
.social-links li:hover {
  animation: animate__jello; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2s;
}

.social-links li a {
  color: styles.$primary-color;
  opacity: 0.5;
  text-decoration: none;
  transition: 0.3s;
  font-family: styles.$font-futura-medium;
}
.social-links li a:hover {
  color: styles.$highlight-color;
  opacity: 1;
}
.profile-intro {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.profile-intro p {
  color: styles.$primary-color;
  opacity: 0.5;
  font-family: styles.$font-futura-medium;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.03em;
  font-weight: 500;
}
.profile-intro p span {
  font-size: 14px;
  text-decoration-line: underline;
  color: styles.$highlight-color;
  cursor: pointer;
  font-style: italic;
}
.hero-img {
  box-sizing: border-box;
  width: 500px;
  height: 500px;
  border-radius: 10px;
  background-color: #444444;
}
.section-two {
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  align-items: center;
  padding: 60px;
}
.process-main {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;
}
.approach-tittle {
  display: flex;
  justify-content: center;
  width: 100%;
  letter-spacing: 2px;
  font-size: 2rem;
  color: styles.$highlight-color;
  font-family: styles.$font-bonheur-royale;
  font-weight: 800;
}
.process-cards {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
}
.process-cards div {
  width: 280px;
  height: fit-content;
  box-sizing: border-box;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  //border: 1px solid #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 40px;
}
//cards background
.process-card-1 {
  background: linear-gradient(
    140deg,
    rgba(103, 255, 119, 0.15) 24.01%,
    rgba(37, 128, 179, 0.15) 100%
  );
}
.process-card-1:hover {
  background: linear-gradient(
    270deg,
    rgba(103, 255, 119, 0.15) 24.01%,
    rgba(37, 128, 179, 0.15) 100%
  );
}
.process-card-2 {
  background: linear-gradient(
    140deg,
    rgba(255, 184, 76, 0.15) 24.01%,
    rgba(37, 128, 179, 0.15) 100%
  );
}
.process-card-3 {
  background: linear-gradient(
    140deg,
    rgba(253, 138, 138, 0.15) 24.01%,
    rgba(37, 128, 179, 0.15) 100%
  );
}
.process-card-4 {
  background: linear-gradient(
    140deg,
    rgba(227, 132, 255, 0.15) 24.01%,
    rgba(37, 128, 179, 0.15) 100%
  );
}
.process-cards div h2 {
  @include styles.tittle;
  text-shadow: none;
  font-size: 24px;
}
.process-cards div p {
  color: styles.$primary-color;
  font-size: 16px;
  opacity: 0.5;
}
.section-three {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cs-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 80px 0;
}
.cs-container hr {
  width: 100%;
  opacity: 0.2;
}

// Responsive Design Starts Here

@media screen and (max-width: 768px) {
  
  .section-two {
    height: auto;
  }
  .process-main {
    width: -webkit-fill-available;
  }
  .process-cards {
    flex-direction: column;
    width: -webkit-fill-available;
  }
  .process-cards div {
    width: auto;
  }
  .approach-tittle {
    font-size: 2rem;
  }
  .main-hero {
    gap: 16px;
  }
  .greet {
    gap: 16px;
  }
  .greet span {
    font-size: 1.2rem;
  }
  .main-title {
    font-size: 2.5rem;
    line-height: 40px;
  }
  .job-title {
    gap: 16px;
  }
  .job-title span {
    font-size: 3.5rem;
    line-height: 40px;
  }
  .align-links {
    gap: 16px;
  }
  .name-seperator {
    margin: 0;
    height: 2px;
    width: -webkit-fill-available;
  }
  .social-links {
    gap: 16px;
  }
  .social-links li a {
    font-size: 14px;
  }
}
